<template>
<v-container style="padding:1px">


    <v-card :loading="loading" elevation="20" style="border: 1px solid #1E88E5">
        <template slot="progress">
            <v-progress-linear color="cyan" indeterminate></v-progress-linear>
        </template>
        <!--v-card-title class="text-color-title text--darken-4 text-xs-subtitle-2 text-lg-h5 text-wrap text-left">
            Signos vitales
        </v-card-title-->
        <v-card-text>

            <v-form ref="form" v-model="valid" :lazy-validation="false">
                    <v-row >
                        <v-col cols="2" >
                                <v-text-field
                                type="number"
                                ref="talla"
                                v-model="data.talla"
                                :counter="5"
                                label="Talla"
                                :rules="[v=>v==null||(v && v.length>0&& v.length<=5 && !isNaN(v)&&v>0.46&&v<220) || 'Valide el formato, longitud.']"
                                @change="onChange($event,'talla')"
                                ></v-text-field>

                        </v-col>
                        <v-col cols="2">
                                <v-text-field
                                type="number"
                                ref="peso"
                                v-model="data.peso"
                                :counter="5"
                                label="Peso"
                                @change="onChange($event,'peso')"
                                :rules="[v=>v==null||(v && v.length>0 && v.length<=5 && !isNaN(v)&&v>2&&v<220) || 'Valide el formato, longitud']"
                                ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                                <v-text-field
                                ref="temperatura"
                                type="number"
                                v-model="data.temp"
                                :counter="4"
                                label="Temperatura"
                                @change="onChange($event,'temperatura')"
                                :rules="[v=>v==null||(v.length>0&& v.length<5 && (!isNaN(v)&&v>35&&v<42)) || 'Rango permitido 36 - 41',
                                ]"
                                ></v-text-field>

                        </v-col>
                        <v-col cols="2">
                            <v-badge
                            dot
                            overlap
                            :value="ta_badge && ta_badge.valor ? ta_badge.valor : 0 "
                            :color="ta_badge.color"
                            offset-x="10"
                            offset-y="15"
                            :title="ta_badge.texto"
                            >
                                    <v-text-field
                                    ref="tension_arterial"
                                    v-model="data.ta"
                                    :counter="7"
                                    label="TA"
                                    @change="onChange($event,'tension_arterial')"
                                    :rules="[v=>!!!v||(!!v&&v.length<8&&/\d{2,3}\/\d{2,3}/.test(v))||'Corrija']"

                                    ></v-text-field>
                            </v-badge>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <v-badge
                            dot
                            overlap
                            :value="fc_badge && fc_badge.valor ? fc_badge.valor : 0 "
                            :color="fc_badge.color"
                            offset-x="10"
                            offset-y="15"
                            :title="fc_badge.texto"
                            >

                                <v-text-field
                                    ref="frecuencia_cardiaca"
                                    type="number"
                                    title="Frecuencia cardiaca"
                                    v-model="data.fc"
                                    :counter="3"
                                    label="FC"
                                    :rules="[v=>v==null||(v && v.length<4 && !isNaN(v)) || 'Valide el formato, sólo se acepta un valor númerico']"
                                    @change="onChange($event,'frecuencia_cardiaca')"

                                    ></v-text-field>
                            </v-badge>
                        </v-col>
                        <v-col cols="2">
                                <v-text-field
                                ref="frecuencia_respiratoria"
                                type="number"
                                title="Frecuencia respiratoria"
                                v-model="data.fr"
                                :counter="2"
                                label="FR"
                                :rules="[v=>v==null||(v && v.length<3 && !isNaN(v)) || 'Valide el formato, sólo se acepta un valor númerico',
                                         ]"
                                @change="onChange($event,'frecuencia_respiratoria')"
                                required
                                ></v-text-field>

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                        <v-badge dot
                                            overlap
                                            :value="spo2_badge && spo2_badge.valor ? spo2_badge.valor : 0 "
                                            :color="spo2_badge.color"
                                            offset-x="10"
                                            offset-y="15"
                                            :title="spo2_badge.texto"
                                            :content="spo2_badge.texto"
                                            >
                            <v-text-field
                                ref="saturacion_oxigeno"
                                type="number"
                                title="SPO2"
                                v-model="data.so"
                                :counter="3"
                                label="SPO2"
                                suffix="%"
                                :rules="spo2_rules"
                                @change="onChange($event,'saturacion_oxigeno')"

                                ></v-text-field>
                        </v-badge>
                        </v-col>
                    </v-row>
                </v-form>
        </v-card-text>
    </v-card>

</v-container>
</template>
<script>
import CitaService from "@/services/cita.service.js";
import PacienteUtils from "@/pacienteUtils.js";

export default {
    name:'VitalSigns',
    props:['p','c', 'fc'],
    components:{

    },
    data:()=>({
        valid:false,
        data:{temp:36},
        ta_badge: {valor: 0, color: '', texto: 'Tensión arterial'},
        fc_badge: {valor: 0, color: '', texto: 'Frecuencia cardiaca'},
        spo2_badge: {valor: 0, color:'', texto: 'Saturación oxígeno'},
        spo2_rules:[
            v=>v==null||(v && v.length<4 && !isNaN(v) && v>=0 && v<=100) || 'Rango permitido 0-100'
        ],
        loading:true
    }),
    watch:{
        valid:function(v){
            console.log(v);
        }
    },
    computed: {
        age: function () {
            if (this.fc && this.fc != '-') {
                let fn = this.fc.split("-");
                let nac = new Date(fn[0], fn[1] - 1, fn[2], 0, 0, 0, 0);
                let ag = PacienteUtils.obt_edad(nac, new Date());
                return ag;
            }
            return null;
        },
    },
    methods:{
        error:function(err){
            this.$root.showMessage(err, 'error');
        },
        onChange:function(v,name){
            if(name==='talla') if(!isNaN(v)&&v>2.2){v=Number.parseFloat(v/100).toPrecision(3); this.data.talla=v}
            if(this.$refs[name].validate()){
                var params={
                    pacienteId:this.p,
                    value:v,
                    citaId:this.c,
                    cname: name
                };

                this.loading = true;
                CitaService.saveVitalSign(params).then((data) => {
                    if(data && data > 0){
                        this.$root.showMessage('Signo vital guardado', 'success');
                    }
                    else{
                       this.$root.showMessage('El signo vital no pudo ser guardado', 'error');
                    }
                })
                .then(function () {
                    this.loading = false;
                }.bind(this));

                this.onValidarSignos(name,v);
            }
            /*else{
                this.$root.showMessage('Valor "'+name+'" incorrecto, no se guardará','warning');
            }*/
        },
        onValidarSignos: function(name, value) {
            if (name == 'tension_arterial') {
                this.alarmaTA(value);
            } else if (name == 'frecuencia_cardiaca') {
                this.alarmaFC(value);
            }
            else if(name == 'saturacion_oxigeno'){
                this.alarmaSPO2(value);
            }
        },
        alarmaSPO2(value){
            var badge= this.spo2_badge;
            badge.valor=0;
            badge.color='';
            if(value){
                let spo2 = new Number(value);
                if(spo2){
                    if(spo2 >= 95 && spo2 <= 100){
                        console.log('saturación oxígeno es normal');
                    }
                    else if(spo2 >= 91 && spo2 <= 94){
                        badge.color = 'yellow darken-1';
                        badge.texto = 'Hipoxia leve';
                    }
                    else if(spo2 >= 86 && spo2 <= 90){
                        badge.color = 'deep-orange';
                        badge.texto = 'Hipoxia moderada';
                    }
                    else if(spo2 < 86){
                        badge.color = 'red darken-2';
                        badge.texto = 'Hipoxia severa';
                    }
                }

                this.spo2_badge = {};
                this.$nextTick(() => {
                    if (badge.color != '')
                        badge.valor = 1;
                    this.spo2_badge = badge;
                });
            }
        },
        alarmaTA(value) {
            var badge = this.ta_badge;
            badge.valor=0;
            badge.color='';
            console.log('alarmaTA: '+value);
            console.log('alarmaTA age: '+this.age.edad_con_decimales);
            if (this.age && this.age.edad_con_decimales >= 18.0)
                if (value) {
                    var sistolica = new Number(value.split("/")[0]);
                    var diastolica = new Number(value.split("/")[1]);
                    if(sistolica && diastolica) {
                        //https://www.who.int/features/qa/82/es/
                        //1Hipertensión: tensión arterial sistólica ≥ 140, o presión arterial diastólica ≥ 90
                        //https://www.heart.org/-/media/data-import/downloadables/4/c/5/whatishighbloodpressure_span-ucm_316246.pdf
                        if (sistolica <= 120 && diastolica <= 80) {
                            //badge.color = 'light-green darken-2';
                            //badge.texto = 'Presión arterial normal';
                        } else if (sistolica <= 129 && diastolica <= 80) {
                            badge.color = 'yellow darken-1';
                            badge.texto = 'Presión arterial elevada';
                        } else if (sistolica <= 139 || diastolica <= 89) {
                            badge.color = 'orange lighten-1';
                            badge.texto = 'Presión arterial alta, nivel 1';
                        } else if (sistolica >= 140 || diastolica >= 90) {
                            badge.color = 'orange darken-3';
                            badge.texto = 'Presión arterial alta, nivel 2';
                            if ((sistolica > 180 && diastolica > 120)||(sistolica > 180 || diastolica > 120)) {
                                badge.color = 'red darken-2';
                                badge.texto = 'Presión arterial muy alta';
                            }
                        }
                    }
                }
                this.ta_badge = {};
                this.$nextTick(() => {
                    if (badge.color != '')
                        badge.valor = 1;

                    this.ta_badge = badge;
                });
        },
        alarmaFC(value) {
            var badge = this.fc_badge;
            badge.valor=0;
            badge.color='';
            if (this.age && this.age.edad_con_decimales >= 18.0)
                if (value) {
                    var fc = new Number(value);
                    //Una frecuencia cardíaca en reposo normal para los adultos oscila entre 60 y 100 latidos por minuto.
                    // www.mayoclinic.org/es-es/healthy-lifestyle/fitness/expert-answers/heart-rate/faq-20057979
                    if (fc >=60 && fc <= 100) {
                        //badge.color = 'light-green darken-2';
                        //badge.texto = 'Normal';
                    } else if (fc > 100) {
                        badge.color = 'red darken-2';
                        badge.texto = 'Taquicardia';//Ritmo cardiaco rápido
                    } else if (fc < 60) {
                        badge.color = 'red darken-2';
                        badge.texto = 'Bradicardia, si el paciente no es atleta entrenado\ny su FC en reposo es menor de 60 ppm,\n'+
                        'especialmente si presenta otros síntomas. \n (desmayos, mareos o dificultad para respirar) ';
                    }
                }

                this.fc_badge = {};
                this.$nextTick(() => {
                    if (badge.color != '')
                        badge.valor = 1;

                    this.fc_badge = badge;
                });

        }
    },

    beforeMount(){
        console.log('fc: '+this.fc);
        CitaService.getVitalsSigns(this.c).then((data) => {
            if(data){
                this.data=data;
                this.alarmaTA(this.data.ta);
                this.alarmaFC(this.data.fc);
                this.alarmaSPO2(this.data.so);
            }else{
                //¿es necesario?
                //this.$root.showMessage('Es importante que captura los signos vitales', 'warning');
            }
        }).then(function () {
            this.loading = false;
        }.bind(this));
    },

}
</script>