<template>
<v-container style="padding:1px">

    <v-card :loading="loading" elevation="10" style="border: 1px solid #1E88E5">
        <template slot="progress">
            <v-progress-linear color="cyan" indeterminate></v-progress-linear>
        </template>
        <!--v-card-title class="text-color-title text--darken-4 text-xs-subtitle-2 text-lg-h5 text-wrap text-left">
            Niveles de glucosa
        </v-card-title-->
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="validform" lazy-validation>
              <v-row>
                <v-col cols="12" xs="12">
                  <v-select
                    v-model="model.indicadorId"
                    class=""
                    label="Horario"
                    :items="indicadores"
                    :menu-props="{ top: false, offsetY: true }"
                    required
                    :rules="[(v) => !!v || 'Requerido']"
                  ></v-select>
                </v-col>
                <v-col cols="4" xs="12">
                  <v-menu
                    ref="menu"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290"
                    max-width="290"
                    class="white"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="model.fecha"
                        label="*Fecha"
                        readonly
                        v-on="on"
                        :rules="[(v) => !!v || 'Requerido']"
                        required
                        hint="yyyy-MM-dd"
                        persistent-hint
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="model.fecha"
                      dense
                      scrollable
                      :max="maxDate"
                      @change="dateChanged"
                      @input="dateMenu = false"
                      locale="es"
                      hint="formato MM/DD/YYYY "
                      persistent-hint
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4" xs="12">
                  <v-text-field
                    v-model.trim="model.mgdl"
                    required
                    :rules="rules"
                    label="*Glucosa"
                    suffix="mg/dl"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  lg="4"
                  class="d-flex justify-end align-center"
                >
                  <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!validform"
                    :loading="saving"
                    @click="save"
                    class="mr-1"
                    outlined
                    >{{ model && model.id ? "Actualizar" : "Registrar" }}</v-btn
                  >
                  <v-btn color="blue darken-1" outlined text @click="reset()"
                    >Limpiar</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col col="12">
                <v-simple-table height="250px" dense >
                  <template v-slot:default>
                    <thead>
                      <tr class="green lighten-5">
                        <th class="text-left">Fecha</th>
                        <th class="text-left">Horario</th>
                        <th class="text-left">Glucosa</th>
                        <th class="text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, idx) in items"
                        :key="item.id"
                        style="cursor: pointer"
                        @click="itemSelected(idx)"
                      >
                        <td class="text-left">
                          {{ item.fecha.substring(0, 10) }}
                        </td>
                        <td class="text-left">
                          {{ item.nombre }}
                        </td>
                        <td class="text-left">
                          <span>
                            <v-icon
                              small
                              :color="obtColor2(item.mgdl, item.indicadorId)"
                              >mdi-circle</v-icon
                            >
                            {{ item.mgdl }} mg/dl
                          </span>
                        </td>
                        <td>
                          <v-btn
                            icon
                            @click="
                              confirmDeletion = true;
                              currentIndex = idx;
                            "
                            title="Eliminar"
                          >

                            <v-icon color="grey lighten-1"
                              >mdi-trash-can-outline</v-icon
                            >
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row v-if="hasElements">
              <span
                class="caption mr-2"
                v-for="ref in referencias"
                :key="ref.title"
              >
                <v-icon small :color="ref.color">mdi-circle</v-icon>
                {{ ref.title }}
              </span>
            </v-row>
          </v-container>

        </v-card-text>
      </v-card>

      <confirm
            v-if="confirmDeletion"
            @cancel="confirmDeletion = false"
            @confirm="
              confirmDeletion = false;
              deleteBloodGlucoseLevel();
            "
          ></confirm>


</v-container>
</template>
<script>

import { formatearFechaAIso } from "@/utils.js";
import CitaService from "@/services/cita.service.js";

export default {
  name:'Glucose',
  props:['c'],
  components: {
    Confirm: () => import("@/components/Confirm"),
  },
  data: () => ({
    dialog: true,
    loading: true,
    sb: { show: false, color: "info", to: 3000, top: false, msg: "" },
    saving: false,
    validform: false,
    dateMenu: false,
    maxDate: null,
    confirmDeletion: false,
    indicadores: [
      { value: 1, text: "Antes del desayuno" },
      { value: 2, text: "2 horas después del desayuno" },
      { value: 3, text: "Antes de la comida" },
      { value: 4, text: "2 horas después de la comida" },
      { value: 5, text: "Antes de la cena" },
      { value: 6, text: "2 horas después de la cena" },
    ],
    referencias: [
      { color: " green lighten-2", title: "Sin diabetes" },
      { color: "yellow lighten-2", title: "Pre-diabetes" },
      { color: "   red lighten-2", title: "Diabetes" },
    ],
    rules: [
      (v) =>
        (v && v.length > 0 && v.length <= 3 && /^[0-9]{1,3}$/.test(v)) ||
        "Sólo se permiten números",
    ],
    model: {
      indicadorId: 1,
    },
    items: [],
    currentIndex: -1,
    lista: -1,
  }),
  beforeMount() {
  console.log('beforeMount Glucose');
    this.maxDate = formatearFechaAIso(new Date());
    // -> toISOString devuelve utc time
    this.model.fecha = this.maxDate;
    this.getBloodGlucoseLevels();

  },
  computed: {
    hasElements: function () {
      return this.items.length > 0;
    },
  },
  methods: {
     message: function (msg, color) {
      this.$root.showMessage(msg, color);
    },
    addBloodGlucoseLevel:function(params){
        CitaService.registerBloodGlucoseLevel(this.c, params).then((data) => {
            if(data && data > 0){
                params.nombre = this.obtNombreByIndicador(params.indicadorId);
                params.id = data;
                var addlist = [params];
                Array.prototype.push.apply(addlist, this.items);
                this.items = addlist;
                this.reset();
            }
            else{
                this.message("No fue posible guardar el nivel de glucosa","error");
            }



        }).then(function () {
            this.saving = false;
        }.bind(this));
    },
    updateBloodGlucoseLevel:function(params){
        CitaService.updateBloodGlucoseLevel(params).then((data) =>{
            if(data && data > 0){
                Object.assign(this.items[this.currentIndex], params);
                this.reset();
            }
            else{
                this.message("No fue posible guardar el nivel de glucosa","error");
            }
        }).then(function () {
                      this.saving = false;
                  }.bind(this));
    },
    deleteBloodGlucoseLevel:function(){
        let glucosaId = this.items[this.currentIndex].id;
        this.loading = true;
        CitaService.deleteBloodGlucoseLevel(glucosaId).then((data)=>{
            if(data && data > 0){
                this.items.splice(this.currentIndex, 1);
            }
            else{
                this.message("No fue posible eliminar el nivel de glucosa", "error");
            }
        }).then(function () {
            this.loading = false;
            this.reset();
            }.bind(this));
    },
    save: function () {
        if (!this.$refs.form.validate()) return;
        this.saving = true;
        var params = Object.assign({}, this.model);
        params.fecha = params.fecha.substring(0, 10);
        if(this.model.id){
            this.updateBloodGlucoseLevel(params);
        }
        else{
            this.addBloodGlucoseLevel(params);
        }

    },
    dateChanged: function (date) {
      this.model.fecha = date.substring(0, 10);
    },
    getBloodGlucoseLevels: function () {
        CitaService.getBloodGlucoseLevels(this.c).then((data) => {
            if(data){
                this.items = data;
            }
        }).then(function () {
            this.loading = false;
        }.bind(this));
    },
    itemSelected: function (index) {
      this.currentIndex = index;
      //  "id": 2, "indicadorId": 2, "nombre": "Antes del desayuno", "mgdl": 150, "fecha": "2021-02-21"
      this.model = {
        id: this.items[index].id,
        indicadorId: this.items[index].indicadorId,
        nombre: this.items[index].nombre,
        mgdl: new String(this.items[index].mgdl),
        fecha: this.items[index].fecha.substring(0, 10),
      };
    },
    obtNombreByIndicador(id) {
      let index = this.indicadores.findIndex(function (t) {
        return t.value === id;
      });
      return this.indicadores[index].text;
    },
    obtColor: function (index) {
      var mgdl;
      var indicadorId;
      if (!index || index == -1) {
        mgdl = this.model.mgdl;
        indicadorId = this.model.indicadorId;
      } else {
        mgdl = this.items[index].mgdl;
        indicadorId = this.items[index].indicadorId;
      }
      return this.obtColor2(mgdl, indicadorId);
    },
    obtColor2: function (mgdl, indicadorId) {
      let index = -1;
      //1,2,3,4,5,6
      indicadorId = new Number(indicadorId);
      mgdl = new Number(mgdl);
      if (mgdl && indicadorId && !isNaN(mgdl))
        if (indicadorId == 1 || indicadorId == 3 || indicadorId == 5) {
          if (mgdl < 101) index = 0;
          //0-> Sin Diabetes
          else if (mgdl < 126)
            //1->Pre Diabetes
            index = 1;
          else index = 2;
        } else {
          if (mgdl < 140)
            //0-> Sin Diabetes
            index = 0;
          else if (mgdl < 200)
            //1->Pre Diabetes
            index = 1;
          else index = 2;
        }

      return index == -1 ? "" : this.referencias[index].color;
    },
    reset: function () {
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.currentItem = -1;
        this.model = {};
        this.model.fecha = this.maxDate;
        this.model.indicadorId = 1;
      });
    },

  },
};
</script>