<template>
   <v-container style="height:auto;">

    <v-tabs  background-color="white" dark v-model="tab" >
        <v-tabs-slider color="cyan accent-2"></v-tabs-slider>
        <v-tab key='tab-1' style="border-top-left-radius:10px;border-top-right-radius:10px;background-color:#1E88E5;">
            <v-icon left>mdi-hand-heart</v-icon>
            Signos vitales
        </v-tab>

        <v-tab key="tab-2" style="border-top-left-radius:10px;border-top-right-radius:10px;background-color:#1E88E5">
            <v-icon left>mdi-diabetes</v-icon>
            Niveles de glucosa
        </v-tab>
        <v-tab-item>
             <vital-signs :p="pacienteId" :c="citaId" @message="message" :fc="fc"></vital-signs>
        </v-tab-item>
        <v-tab-item>
            <blood-glucose :p="pacienteId" :c="citaId"></blood-glucose>
        </v-tab-item>

    </v-tabs>

    </v-container>
</template>
<script>
import VitalSigns from '@/components/VitalSigns'
import Glucose from '@/components/Glucose'

export default {
    props:['fc'],
    data:function(){
        return {
            pacienteId: undefined,
            citaId: undefined,
            tab:'tab-1',
        }
    },
    components:{
        'vital-signs':VitalSigns,
        'blood-glucose':Glucose
    },
    computed:{

    },
    methods:{
        message:function(msj, color){

        }
    },
    beforeMount(){
            this.pacienteId = this.$route.params.pacienteId;
            this.citaId = this.$route.params.tareaId;
            if(typeof this.pacienteId === "undefined" ||  typeof this.citaId === "undefined"){
                //¿debo retornar a la página de agenda?
            }

            console.log(this.pacienteId + ", "+ this.citaId)

    }


}
</script>
